<template>

<div class="col-12" style="height:140px;">

    <div class="col-6 float-start" style="padding:1%;">
    <b>フレームの幅</b> <input class="form-control" type="text" v-model="w"  ><br>
    </div>
    <div class="col-6 float-end" style="padding:1%;">
    <b>フレームの高さ</b> <input  class="form-control" type="text" v-model="h" >
    </div>
</div>
<div class="col-12" style="height:140px;">

    <div class="col-6 float-start" style="padding:1%;">
    <b>URL</b> <input  class="form-control" type="text" v-model="url" >
    </div>
    <div class="col-6 float-end" style="padding:1%;">
    <b>APIToken</b> <input  class="form-control" type="text" v-model="authorization" >
    </div>
</div>



<Jupyter ref="jupyter" :path="path" >
</Jupyter>
<hr>


 <div style="height:200px;">
    
       <MonacoEditor 
        class="editor" 
        ref="editor"
        :options="option"
        v-model="text"
        language="sql" 
        @change="editorDidChange"
       >
       </MonacoEditor>
    
    </div>
<button class="btn btn-dark" v-on:click="updateSql" > SQLを実行 </button>
</template>

<script>

import MonacoEditor from 'monaco-editor-vue3'

import Jupyter from "../../components/chart/Jupyter.vue";

export default {
  name: 'JupyterView' ,
  components:  { Jupyter,MonacoEditor } ,
  data(){  return {
        w : "100%" , 
        h : "800px" , 
        url : "",
        authorization:"",
        path : "" ,
        text : "" , 
        option : {"value":this.text}
        };
    },
  async created( ) {
      
      this.path = window.location.href;   
      const param = new URLSearchParams(window.location.search);
       const q = param.get('q');
       if(q){
       this.text = q ;
       this.option.value = q;
       }
               },
    methods: {
        
        editorDidChange(text){     
            this.text  = text;  
        }, 
       async updateSql (){
        
        const q = this.text.replaceAll('+','%2B');
          const path = window.location.href + "&q="+q + "&url="+this.url + "&authorization="+this.authorization + "&w="+this.w + "&h="+this.h;
            
                 if ( window.parent !== window &&  !window.isCrossOrigin ) { 
          
               window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + "?chartSql="+ q.replaceAll ( '+','%2B' )   ) ;  

          }
           this.$refs.jupyter.showData(window.parent.Sql,path );

        }
    }
}


</script>

<style>
main{
width: 100% !important ;
}

.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}</style>