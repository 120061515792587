<template>
<div v-html="html" style="font-size: 24px;" >
</div>
</template>

<script>


export default {
  name: 'Metric',
    props:[ "path", "Sql"]  ,
  data (){
      return {
          html : ""
      };
  },
  async created (){
     
  },
  async mounted(  ) {
     
        //   await this.fetchData();
   
    if ( ! window.isCrossOrigin ){
      
      await this.showData(await window.parent.getSql() ,this.path);   
     } else {

      await this.showData(await window.getSql() ,this.path);   
     }
    },
    methods:{
       async showData ( Sql,path){
       try{
          if(!path){
              path =this.path;
          }
        
          path = encodeURI(path);
         
          path = decodeURI(path);
       
          path = path.replaceAll (  '+', '%2B' )
          const p=path.split('?');
          
         const param = new URLSearchParams(  "?" + p[1] );
         var  q = param.get('q') ;
        
        const particleSize  = param.get('particle_size')  ;
        const  aggregate  = param.get('agg');
        const startDate   = param.get('start')  ;
        const  endDate  = param.get('end');

        const url  = param.get('url');
        const authorization  = param.get('authorization');

        q = q.replaceAll("{START_DATE}", startDate );
        q = q.replaceAll("{END_DATE}",endDate);   
        q = q.replaceAll("{PARTICLE_SIZE}", particleSize);
        q = q.replaceAll("{AGGREGATE}",  aggregate );   
 
          const temps =[]
          if(startDate){
             temps.push("startDate=" + startDate);
          }
          if(endDate){
             temps.push("endDate=" + endDate);
          }
          if(endDate){
             temps.push("endDate=" + endDate);
          }
          if(particleSize){
             temps.push("particleSize=" + particleSize);
          }
          if(aggregate){
             temps.push("aggregate=" + aggregate);
          }
          if(aggregate){
             temps.push("aggregate=" + aggregate);
          }

          this.q = "?" + temps.pop();
          temps.map((v)=>{
            this.q +="&"+v;
          })

        var resu ;
         Sql.executeAsyncQueue( q , async (resu)=>{ 
         
            const data=JSON.stringify(resu);
            
            await fetch(url+this,p+"&data="+data, {
                headers: {
                Authorization: authorization
                }
            });

            try {
            const r = await a.json();
            this.html = JSON.stringify(r) ;
            }catch(e){
                console.log(e);
            }
               /*
               for ( var res = resu.shift(); res  ;  res = resu.shift() ){
               
                var html ="<table style='display: inline-block; width: 100%;'><tbody style='display: inline-block; width: 100%;'>";
                   
               const w = 100/ res.columns.length;
                var t = res.columns.shift();
                
               html +="<tr style='display: inline-block; width: 100%;'>";
               for(;t;){
                    html+= "<th style='display:inline-block; width:"+w+"%; '>" + t + "</th>";
                    t =  res.columns.shift();
               }
               html +="</tr>"
                
                for ( var values = res.values.shift() ; values ; values = res.values.shift()){ 
               
                    html +="<tr style='display: inline-block; width: 100%;'>";
                //    console.log( values);
                    for( var v = values.shift() ;v!==undefined; v = values.shift()){
                        if (v===null){
                            v= "-";
                        }
                        html += "<td style='display:inline-block; width:"+w+"%;'>" + v + "</td>";
                    }
                    html +="</tr>";
               }
                  html +="</tbody></table>";
                  
                  this.html +=html ;
              } */
        });
        await Sql.executeAsync();
         
       
       }catch (e){
           console.log(e);  
        }
    }
    }
};

</script>
